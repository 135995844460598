// 人员模块-花名册
import { ROSTER, USER, DICTIONARY, BASE, UPLOAD, GLOBAL_SETTING } from '../api'
import { METHOD, request } from '@/utils/request'

// 人员模块-花名册-列表
export async function getRosterList(params) {
  return request(`${USER}/roster/getRosterList`, METHOD.GET, params)
}

// 获取表头配置数据
export async function getRosterHeadersConfig(params) {
  return request(`${USER}/roster/getRosterHeadersConfig`, METHOD.GET, params)
}

// 提交表头配置数据
export async function configRosterHeaders(params) {
  return request(`${USER}/roster/configRosterHeaders`, METHOD.POST, params)
}
// 查询基本信息
// 列表接口放这里

// 人员模块-花名册-个人信息页

// 查询基本信息
export async function getStaffInfoByOnJobId(params) {
  return request(`${ROSTER}/getStaffInfoByOnJobId`, METHOD.GET, params)
}
// 查询个人基本信息
export async function getStaffAllInfoByOnJobId(params) {
  return request(`${ROSTER}/getStaffAllInfoByOnJobId`, METHOD.GET, params)
}
// 从档案审核到员工信息页面
export async function getArchivesAllInfo(params) {
  return request(`${USER}/archives/getArchivesAllInfo`, METHOD.GET, params)
}
// 获取档案完善审核页的文件信息
export async function archivesGetStaffFileList(params) {
  return request(`${USER}/archives/getStaffFileList`, METHOD.GET, params)
}
// 新增个人基本信息
export async function createStaffInfo(params) {
  return request(`${ROSTER}/createStaffInfo`, METHOD.POST, params)
}
// 更新个人基本信息
export async function updateStaffInfo(params) {
  return request(`${ROSTER}/updateStaffInfo`, METHOD.POST, params)
}
// 根据字段组ID获取字段列表
export async function getFieldConfigList(params) {
  return request(`${ROSTER}/getFieldConfigList`, METHOD.GET, params)
}

// 根据onJobId获取员工基本信息
export async function getStaffInfo(params) {
  return request(`${BASE}/hrsaas-base/staff/getStaffInfo`, METHOD.GET, params)
}
// 批量获取员工基本信息
export async function getStaffInfoList(params) {
  return request(`${BASE}/hrsaas-base/staff/getStaffInfoList`, METHOD.GET, params)
}
// 生成工号
export async function createStaffNum(params) {
  return request(`${BASE}/hrsaas-base/staffNum/createStaffNum`, METHOD.POST, params)
}

// 员统计数据查询接口
export async function getStaffStatistics(params) {
  return request(`${ROSTER}/getStaffStatistics`, METHOD.GET, params)
}

// 查询员工列表
export async function getStaffListByShowField(params) {
  return request(`${ROSTER}/getStaffListByShowField`, METHOD.GET, params)
}

// 员工基本信息
export async function getStaffInfoApi(params) {
  return request(`${BASE}/hrsaas-base/staff/getStaffInfo`, METHOD.GET, params)
}

// 下载批量导入模板
export async function downLoadTemplate(params) {
  return request(`${UPLOAD}/file`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 批量导入员工信息
export async function batchImportStaff(params) {
  return request(`${ROSTER}/batchImportStaff`, METHOD.POST, params)
}

// 批量导入员工信息
export async function checkBatchImportStaff(params) {
  return request(`${ROSTER}/checkBatchImportStaff`, METHOD.POST, params)
}

// 查询部门列表
export async function getOrgTree(params) {
  return request(`${ROSTER}/getOrgTree`, METHOD.GET, params)
}

// 导出花名册
export async function exportStaffList(params) {
  return request(`${ROSTER + '/exportStaffList'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 新增员工信息和入职信息
export async function createStaffAndOnJob(params) {
  return request(`${ROSTER}/createStaffAndOnJob`, METHOD.POST, params)
}
// 删除联系人
export async function deleteStaffContacts(params) {
  return request(`${USER}/staffContacts/deleteStaffContacts`, METHOD.POST, params)
}
// 删除教育经历
export async function deleteStaffEdu(params) {
  return request(`${USER}/staffEdu/deleteStaffEdu`, METHOD.POST, params)
}
// 删除工作经历
export async function deleteStaffWorkBefore(params) {
  return request(`${USER}/staffWorkBefore/deleteStaffWorkBefore`, METHOD.POST, params)
}
// 删除培训经历
export async function deleteStaffTrainBefore(params) {
  return request(`${USER}/staffTrainBefore/deleteStaffTrainBefore`, METHOD.POST, params)
}
// 删除合同记录
export async function deleteStaffContract(params) {
  return request(`${USER}/staffContract/deleteStaffContract`, METHOD.POST, params)
}
// 删除奖惩记录
export async function deleteStaffAward(params) {
  return request(`${USER}/staffAward/deleteStaffAward`, METHOD.POST, params)
}
// 人员模块-花名册-附件材料
// 获取员工的员工附件列表
export async function getStaffFileList(params) {
  return request(`${DICTIONARY}/staffFile/getStaffFileList`, METHOD.GET, params)
}
// 新增员工附件
export async function createStaffFile(params) {
  return request(`${DICTIONARY}/staffFile/createStaffFile`, METHOD.POST, params)
}
// 修改员工附件
export async function updateStaffFile(params) {
  return request(`${DICTIONARY}/staffFile/updateStaffFile`, METHOD.POST, params)
}
// 删除员工附件
export async function deleteStaffFile(params) {
  return request(`${DICTIONARY}/staffFile/deleteStaffFile`, METHOD.POST, params)
}
// 删除员工附件与文件的关联关系
export async function deleteStaffFileRelation(params) {
  return request(`${DICTIONARY}/staffFile/deleteStaffFileRelation`, METHOD.POST, params)
}

// 人员模块-花名册-信息记录
// 查询个人成长记录
export async function getStaffGrowthList(params) {
  return request(`${USER}/staffGrowth/getStaffGrowth`, METHOD.GET, params)
}
// 培训记录
export async function getStaffTrainBefore(params) {
  return request(`${USER}/staffTrainBefore/getStaffTrainBefore`, METHOD.GET, params)
}
// 合同记录
export async function getStaffContract(params) {
  return request(`${USER}/staffContract/getStaffContract`, METHOD.GET, params)
}

// 修改员工附件
export async function examEntryForm(params) {
  return request(`${DICTIONARY}/transfer/applyEntry/examEntryForm`, METHOD.POST, params)
}

// 人员模块-花名册-扫码邀请员工流程
// 展示邀请加入二维码
export async function getQrCode(params) {
  return request(`${USER}/qrCode/getQrCode`, METHOD.GET, params)
}

// 人员模块-获取入职登记表信息
export async function getEntryFormExamInfo(params) {
  return request(`${DICTIONARY}/transfer/applyEntry/getEntryFormExamInfo`, METHOD.GET, params)
}

// 人员模块-获取离职人员信息
export async function getLeaveArchivesByOnJobId(params) {
  return request(`${DICTIONARY}/staffLeaveInfo/getLeaveArchivesByOnJobId`, METHOD.GET, params)
}

// 人员模块-获取离职人员顶部信息
export async function getStaffInfoFromLeaveInfo(params) {
  return request(`${DICTIONARY}/staffLeaveInfo/getStaffInfoFromLeaveInfo`, METHOD.GET, params)
}

// 人员模块-获取入职登记表附件信息
export async function getEntryFormExamFileInfo(params) {
  return request(`${DICTIONARY}/transfer/applyEntry/getEntryFormExamFileInfo`, METHOD.GET, params)
}
// 重置密码
export async function resetPasswordApi(params) {
  return request(`${GLOBAL_SETTING}/reset/password`, METHOD.GET, params)
}
