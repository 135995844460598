<template>
  <a-modal
    ref="modal"
    :visible="visible"
    :width="size | sizeList"
    :mask-closable="maskClosable"
    :ok-text="okText"
    :cancel-text="cancelText"
    :footer="footer"
    :closable="closable"
    :keyboard="keyboard"
    :confirm-loading="confirmLoading"
    :destroy-on-close="destroyOnClose"
    :z-index="zIndex"
    @ok="handleOk"
    @cancel="handleCancel"
    @afterClose="afterClose"
  >
    <!-- 标题区域 -->
    <template slot="title">
      <a-icon
        v-if="type !== ''"
        :type="typeList[type].name"
        theme="filled"
        :style="{color:`${typeList[type].color}`, marginRight: `8px`}"
      />
      <span>{{ title }}</span>
      <slot name="title" />
    </template>
    <!-- 内容区域 -->
    <div class="modal-content" :style="{ overflow: overflowAuto ? 'auto' : 'inherit', ...customStyle }">
      <slot />
    </div>
    <!-- 底部额外按钮插槽 -->
    <template slot="footer">
      <slot name="footer" />
    </template>
  </a-modal>
</template>

<script>
const sizeList = {
  large: '916px',
  normal: '556px'
}
export default {
  name: 'Modal',
  filters: {
    sizeList(val) {
      return sizeList[val]
    }
  },
  model: {
    prop: 'visible',
    event: 'getVisible'
  },
  props: {
    // 标题图标类型
    type: {
      type: String,
      default: ''
    },
    // 对话框尺寸
    size: {
      type: String,
      default: ''
    },
    // 对话框显隐
    visible: {
      type: Boolean,
      default: false
    },
    // 标题
    title: {
      type: [String, Object],
      default: () => {}
    },
    // 点击蒙层是否关闭
    maskClosable: {
      type: Boolean,
      default: false
    },
    // 显示右上角图标
    closable: {
      type: Boolean,
      default: false
    },
    // 是否支持键盘esc关闭
    keyboard: {
      type: Boolean,
      default: false
    },
    // 确定按钮loading
    confirmLoading: {
      type: Boolean,
      default: false
    },
    // 确认文案
    okText: {
      type: String,
      default: '确认'
    },
    // 取消文案
    cancelText: {
      type: String,
      default: '取消'
    },
    // 关闭时销毁 modal 里的子元素
    destroyOnClose: {
      type: Boolean,
      default: true
    },
    // 底部按钮
    footer: {
      type: [String, Object],
      default: () => {}
    },
    zIndex: {
      type: Number,
      default: 2022
    },
    customStyle: {
      type: Object,
      default: () => {}
    },
    getContainer: {
      type: Function,
      default: () => { return document.querySelector('.modal-content') }
    }
  },
  data() {
    return {
      topHeight: 0,
      typeList: {
        info: { name: 'info-circle', color: '#5179FB' },
        success: { name: 'check-circle', color: '#00A870' },
        warning: { name: 'exclamation-circle', color: '#ED7B2F' },
        error: { name: 'exclamation-circle', color: '#E34D59' }
      },
      overflowAuto: true
    }
  },
  watch: {
    visible: {
      handler: function(val) {
        if (val) {
          this.resetOverflow()
        }
      },
      immediate: true,
      deep: true
    }
  },
  updated() {
    this.resetOverflow()
  },
  methods: {
    // 对话框关闭时动画结束的事件回调
    afterClose() {
      this.$emit('after-close')
    },
    // 确定事件
    handleOk() {
      this.$emit('ok')
    },
    // 取消事件
    handleCancel() {
      this.$emit('cancel')
    },
    resetOverflow() {
      setTimeout(() => {
        const ele = document.querySelector('.modal-content')
        if (ele) {
          const contentOffset = ele.offsetHeight
          const contentScroll = ele.scrollHeight
          if (contentOffset >= contentScroll) {
            this.overflowAuto = false
          } else {
            this.overflowAuto = true
          }
        }
        // console.log('contentOffset', contentOffset)
        // console.log('contentScroll', contentScroll)
        // console.log('slotEl', this.overflowAuto)
      }, 100)
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .ant-modal-wrap {
		overflow: hidden;
}
/deep/ .ant-modal {
  // height: auto;
  max-height:calc(100vh - 20vh);
  padding-bottom: 0;
  top:10vh;
}
/deep/ .ant-modal-body {
  padding: 0 !important;
  max-height: calc(100vh - 20vh - 110px);
  // overflow: auto;
}
/deep/.ant-modal-content{
  max-height: calc(100vh - 20vh);
}
.modal-content {
  width: 100%;
  // min-height: 340px;
  max-height: calc(100vh - 20vh - 110px);
  padding: 20px 24px 32px;
  // 滚动条
  &::-webkit-scrollbar{
    width: 4px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: @sc-greyBg-20;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    border-radius: 3px;
    background:#fff;
  }
}
/deep/.ant-modal-content .ant-modal-footer{
  padding: 0 24px 24px;
  .ant-btn{
    margin-left: 12px;
    width: auto;
    padding: 5px 10px;
    color: @sc-grey-100;
    span {
      font-size: 14px;
    }
    &:hover {
      color: @sc-primary-100;
    }
  }
  .ant-btn-primary{
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
/deep/ .ant-modal-header {
  color: @sc-grey-100;
  .font-size(16px);
}
/deep/ .anticon-info-circle {
  color: @sc-primary-100;
}
/deep/ .ant-form-item {
  margin-bottom: 20px;
  &:last-child{
      margin-bottom: 0;
    }
}
/deep/ .ant-checkbox-wrapper {
  color: @sc-grey-80;
  .font-size(14px);
}
/deep/ .ant-space-align-center {
  margin-bottom: 20px;
}

</style>
