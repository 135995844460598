const config = {
  403: {
    img: require('@/assets/img/403.png'),
    title: '暂无访问权限',
    desc: '抱歉，您暂无访问权限...'
  },
  404: {
    img: require('@/assets/img/404.png'),
    title: '未找到访问页面',
    desc: '抱歉，您访问的页面仍在开发中...'
  },
  500: {
    img: require('@/assets/img/500.png'),
    title: '500',
    desc: '抱歉，页面出错了'
  }
}

export default config
