<template>
  <div class="recodeSelect">
    <a-select v-model="val" style="width: 100%" @change="change">
      <a-select-option
        v-for="(item, index1) in options"
        :key="`recodeSelectItem${index1}`"
        :value="item.value"
      >
        {{ item.label }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'ReCodeSelect',
  model: {
    prop: 'defultVal',
    event: 'echoVal'
  },
  props: {
    participantType: {
      type: String,
      default: ''
    },
    defultVal: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      val: ''
    }
  },
  watch: {
    defultVal(newVal) {
      if (this.defultVal.length > 0) {
        this.val = this.defultVal[0].participant
      } else {
        this.val = undefined
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.defultVal.length > 0) {
        this.val = this.defultVal[0].participant
      } else {
        this.val = undefined
      }
    })
  },
  methods: {
    change() {
      if (!this.val && this.val !== 0) {
        this.$emit('echoVal', [])
      } else {
        // 初始化数据还没过来的时候不去改变原有值
        if (this.options.length === 0) {
          return
        }
        let obj = {
          participantType: this.participantType,
          participant: this.val,
          participantName: ''
        }
        this.options.forEach(item => {
          if (item.value === this.val) {
            obj.participantName = item.label
          }
        })
        this.$emit('echoVal', [obj])
      }
    }
  }
}
</script>
<style lang="less" scoped>
.recodeSelect{
  width: 100%;
}
</style>
