<template>
  <div class="exception-page">
    <div class="img">
      <img :src="config[type].img">
    </div>
    <div class="content">
      <h1>{{ config[type].title }}</h1>
      <div class="desc">{{ config[type].desc }}</div>
      <div class="action">
        <a-button type="primary" @click="backHome">返回首页</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import Config from './typeConfig'

export default {
  name: 'ExceptionPage',
  props: {
    type: {
      type: [Number, String],
      default: 0
    },
    homeRoute: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      config: Config
    }
  },
  methods: {
    backHome() {
      if (this.homeRoute) {
        this.$router.push(this.homeRoute)
      }
      this.$emit('backHome', this.type)
    }
  }
}
</script>

<style lang="less" scoped>
  .exception-page{
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @base-bg-color;
    .img{
      padding-right: 175px;
      zoom: 1;
      img{
        max-width: 381px;
      }
    }
    .content{
      h1{
        color: @sc-grey-100;
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
        margin-bottom: 26px;
      }
      .desc{
        color: @sc-grey-60;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 60px;
      }
    }
  }

</style>
